const spacings = () => ({
  sectionMaxWidth: "1440px",
  caseStudyMaxWidth: "1100px",
  homeDescriptionMaxWidth: "1280px",
  mobilePadding: "26px",
  tabletPadding: "40px",
  desktopPadding: "50px",
  scrollOffset: 60,
});

export default spacings;
