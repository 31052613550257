const fontSizes = () => ({
  entryText: "14px",
  menuLink: "15px",
  featureContent: "17px",
  opinionsPosition: "17px",
  opinionsAuthor: "18px",
  statsDescription: "20px",
  featureTitle: "21px",
  caseStudyScope: "22px",
  caseStudyParagraph: "24px",
  caseStudyContent: "26px",
  h4: "36px",
  entryTextLineHeight: "16px",
  menuLinkLineHeight: "18px",
  opinionsPositionLineHeight: "20px",
  opinionsAuthorLineHeight: "21px",
  featureLineHeight: "25px",
  caseStudyScopeLineHeight: "25px",
  statsDescriptionLineHeight: "28px",
  caseStudyParagraphTitleLineHeight: "30px",
  caseStudyParagraphLineHeight: "36px",
  caseStudyScopeElementLineHeight: "37px",
  caseStudyScopeContentLineHeight: "40px",
  h4LineHeight: "42px",
  photoWithTextLineHeight: "68px"
});

export default fontSizes;
