import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Cookies from "universal-cookie";

import { colors, breakpoints } from "../../globalUI";

const Banner = styled.div`
  width: 260px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border-radius: 4px;
  background: ${colors().white};
  z-index: 1;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.02);
  display: ${props => props.visible ? "block" : "none"};
  @media (${breakpoints().minS}) {
    bottom: 40px;
    left: 40px;
  }
  @media (${breakpoints().minM}) {
    bottom: 50px;
    left: 50px;
  }
`;

const Content = styled.div`
  padding: 26px 27px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const ReadMoreLink = styled.a`
  color: ${colors().helpText};
  text-decoration: none;
  font-weight: 400;
`;

const Dot = styled.span`
  margin: 0 9px;
`;

const CookieConfirm = styled.button`
  border: 0;
  border-top: 1px solid ${colors().grey};
  border-radius: 0;
  width: 100%;
`;

class CookieBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cookie: "1"
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    this.setState({ cookie: cookies.get("cookie-confirm") });
  }

  confirmCookie() {
    const cookies = new Cookies();
    cookies.set("cookie-confirm", "1", { path: "/" });

    this.setState({
      cookie: "1"
    });
  }

  render() {
    const { cookie } = this.state;

    return (
      <Banner visible={!cookie}>
        <Content>
          <FormattedMessage id="global.cookieBanner.use" />
          <Dot>•</Dot>
          <ReadMoreLink target="_blank" rel="noopener noreferrer" href="/competence_privacy_policy.pdf">
            <FormattedMessage id="global.cookieBanner.readMore" />
          </ReadMoreLink>
        </Content>
        <div>
          <CookieConfirm onClick={() => this.confirmCookie()}>
            <FormattedMessage id="global.cookieBanner.confirm" />
          </CookieConfirm>
        </div>
      </Banner>
    );
  }
}

export default CookieBanner;