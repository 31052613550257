exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-competences-mapping-js": () => import("./../../../src/pages/competences-mapping.js" /* webpackChunkName: "component---src-pages-competences-mapping-js" */),
  "component---src-pages-elearning-trends-js": () => import("./../../../src/pages/elearning-trends.js" /* webpackChunkName: "component---src-pages-elearning-trends-js" */),
  "component---src-pages-few-words-about-adaptive-learning-js": () => import("./../../../src/pages/few-words-about-adaptive-learning.js" /* webpackChunkName: "component---src-pages-few-words-about-adaptive-learning-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-with-smartphones-possible-with-mlearning-js": () => import("./../../../src/pages/learning-with-smartphones-possible-with-mlearning.js" /* webpackChunkName: "component---src-pages-learning-with-smartphones-possible-with-mlearning-js" */),
  "component---src-pages-micro-moments-in-e-learning-js": () => import("./../../../src/pages/micro-moments-in-e-learning.js" /* webpackChunkName: "component---src-pages-micro-moments-in-e-learning-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-semilac-js": () => import("./../../../src/pages/semilac.js" /* webpackChunkName: "component---src-pages-semilac-js" */),
  "component---src-pages-tailor-made-open-source-js": () => import("./../../../src/pages/tailor-made-open-source.js" /* webpackChunkName: "component---src-pages-tailor-made-open-source-js" */),
  "component---src-pages-volkswagen-financial-services-js": () => import("./../../../src/pages/volkswagen-financial-services.js" /* webpackChunkName: "component---src-pages-volkswagen-financial-services-js" */)
}

