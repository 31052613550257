const colors = () => ({
  white: "#fff",
  black: "#000",
  lightGrey: "#f6f6f6",
  vwfsGrey: "#f5f5f5",
  grey: "#e0e3e8",
  darkGrey: "#a3a3a3",
  transparentGrey: "rgba(17,17,17,0.25)",
  red: "#f00",
  defaultText: "#2b2b2c",
  helpText: "#a9acb2",
  descriptionText: "#757779",
  blueSection: "#005cff",
  sandy: "#fff2e1",
  opinionsBorder: "#e0d4c5",
  sendFormButton: "#e5e6e8",
  sendFormButtonShadow: "rgba(0,0,0,0.15)",
  helpButtonBorder: "rgba(0, 0, 0, 0.1)"
});

export default colors;
