import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { IntlProvider } from "react-intl";

import englishMessages from "../i18n/en.json";
import CookieBanner from "../components/global/cookieBanner";
import Transition from "../components/transition";

import "./layout.css";

const Layout = ({ pageContext: { locale }, children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <IntlProvider locale={locale} messages={englishMessages}>
        <Transition location={location}>
          <main>
            {children}
          </main>
          <CookieBanner />
        </Transition>
      </IntlProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
