const transitionDelay = 500;

const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    const delay = 800;

    switch (location.hash) {
      case "#modules":
        window.setTimeout(() => document.getElementById("modules").scrollIntoView(), delay);
        setTimeout(() => window.scrollBy(0, -60), 900);
        break;
      case "#gamification":
        window.setTimeout(() => document.getElementById("gamification").scrollIntoView(), delay);
        break;
      case "#social":
        window.setTimeout(() => document.getElementById("social").scrollIntoView(), delay);
        break;
      case "#production":
        window.setTimeout(() => document.getElementById("production").scrollIntoView(), delay);
        break;
      case "#services":
        window.setTimeout(() => document.getElementById("services").scrollIntoView(), delay);
        setTimeout(() => window.scrollBy(0, -60), 900);
        break;
      default:
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    }
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
  }

  return false;
};

const onClientEntry = async () => {  
  if (typeof window.IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }
};

export { shouldUpdateScroll, onClientEntry };